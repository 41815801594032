@font-face {
  font-family: 'Garnett';
  src: url('assets/fonts/Garnett-Regular.ttf');
}

@font-face {
  font-family: 'Circular Std';
  src: url('assets/fonts/CircularStd-Medium.otf');
}
.video-background {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 50%;
  /* padding-bottom: 56.25%; */
}
.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Change the alpha value for desired transparency */
}
.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .video-background {
    padding-bottom: 100%; /* Adjust the aspect ratio for smaller screens */
  }
}
.navy-blue {
  background-color: #36309E;
}

.dark-blue {
  background-color: #080B52;
  padding-top: 3%;
  padding-bottom: 5%;
}

.w-100 {
  width: 100%;
}

.gray {
  background: #EAE9F4 !important;
}

.deep-blue {
  background-color: #080B52;
}

.yellow {
  background-color: #FFEF98;
}

.black {
  background: #0B0E17;
}

.tab-heading {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  color: #0C0C0D;
}

.newsletter-heading{
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  color: white;
}

.trust {
  font-family: 'Garnett';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #999BCF;
}

.tab-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #18181B;
  padding-right: 10%;
}

.tab-points {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #18181B;
  padding-left: 2%;
  padding-top: 3%;
}

.learn-more-tab-button {
  width: 158px;
  height: 60px;
  border: none;
  background: #007AFF;
  border-radius: 8px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
}

.focus {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.09em;
  color: #FFFFFF;
}

.w-100 {
  width: 100%;
}

.center {
  display: table;
  margin: auto;
}

.decoration {
  text-decoration: none;
}

.catagory-box {
  font-family: 'Circular Std';
  font-style: normal;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  padding: 8%;
  border-radius: 10px;
}

.feature-box {
  width: 100%;
  height: 100%;
  padding: 8%;
  background: #36309E;
  box-shadow: 2px 4px 36px rgba(205, 205, 205, 0.15);
  border-radius: 20px;
}
.mobile-feature-box {
  width: 100%;
  height: 370px;
  padding: 8%;
  background: #36309E;
  box-shadow: 2px 4px 36px rgba(205, 205, 205, 0.15);
  border-radius: 20px;
}

.feature-heading {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  color: #FFFFFF;
}

.background {
  background-image: url('assets/images/background.svg');
  height: 350px;
  width: 100%;
  background-size: cover;
}

.privacy {
  font-family: 'Garnett';
  font-style: bold !important;
  font-weight: 800;
  font-size: 64px;
  line-height: 89px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding-top: 10%;
}

.zasta-privacy {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
}

.feature-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #D8D7ED;
}

.started {
  font-family: 'Garnett';
  font-style: normal;
  font-weight: 800;
  font-size: 58px;
  line-height: 60px;
  text-align: center;
  color: #3084c3;
}

.start-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 25px;
  text-align: center;
  color: #198754;
}

.catagory-name {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #1E1F21;
}

.modal-header{
  border: none !important;
}

.modal-box{
  width: 100px;
  height: 100px;
  display: table;
  margin: auto;
}

.thanks {
  font-family: 'Garnett';
  font-style: bold !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 17px;
  text-align: center;
  color: #007AFF;
}

.modal-txt {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #18181B;
}

.modal-footer{
  border: none !important;
}


.catagory-name-font {
  font-family: 'Garnett';
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 20px;
  color: #1E1F21;
}

.logo-footer{
  width: 50%;
}
.footer-flex{
  display: flex;
  margin-bottom: 10px;
}


.footer-line{
  color: white;
  background-color: white;
  height: 10px;
  border: none;
  margin: 10px 0;
}
.catagory-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #0E0E0F;
}

.process {
  font-family: 'Garnett';
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 67px;
  text-align: center;
  color: #FFFFFF;
}

.portfolio {
  font-family: 'Garnett';
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 67px;
  text-align: center;
  color: #FFFFFF;
}

.zasta-portfolio {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #D6D8FB;
}

.question {
  font-family: 'Garnett';
  font-style: bold !important;
  font-weight: 800;
  font-size: 48px;
  line-height: 67px;
  text-align: center;
  color: #FFFFFF;
}

.zasta-about-us {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #D6D8FB;
}

.contact-us {
  font-family: 'Garnett';
  font-style: bold !important;
  font-weight: 700;
  font-size: 64px;
  line-height: 89px;
  color: #FFFFFF;
}

.nav-pills {
  font-family: 'Circular Std' !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  color: #FFFFFF !important;
  margin-left: 12%;
}

.nav-pills .nav-link {
  background: #F8F9FB;
  width: 140px;
}

.nav-link {
  color: black !important;
  text-decoration: none !important;
}
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: white !important;
  background: #36309E !important;
}

.mission {
  margin-left: 3%;
}

.value {
  margin-left: 3%;
}

.talk-main {
  width: 218px;
  height: 65px;
  border: 3px solid #FFFFFF;
  border-radius: 6px;
  background-color: #36309E;
  font-family: 'Circular Std';
  font-style: normal;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
}

.zasta {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  padding-right: 5%;
  color: #FFFFFF;
}

.software {
  font-family: 'Garnett';
  font-style: bold !important;
  font-weight: 800;
  font-size: 54px;
  line-height: 75px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.new {
  font-family: 'Garnett';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: #7B75EA;
}
.heading {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
  color: #000000;
  padding-top: 10%;
}

.product-heading {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
  color: white;
}

.sub-heading {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #18181B;
}

.contact-txt {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #FFFFFF;
}

.contact-section {
  padding: 10%;
  padding-left: 7% !important;
}

.arrow-img {
  width: 90%;
}

.contact-box {
  position: relative;
  width: 95%;
  margin-top: -36%;
  background: #FFFFFF;
  box-shadow: -27px 32px 32px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 4%;
}

.project {
  font-family: 'Garnett';
  font-style: bold !important;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  text-align: center;
}

.req-field {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: black;
}

.req-chat-field{
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 10px;
  color: black;
}

.mob-app {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 23px;
  color: black;
}

.important {
  color: #007AFF;
}

input[type=text] {
  width: 100% !important;
  box-sizing: border-box !important;
  border: none !important;
  border-radius: 3px;
  border-bottom: 2px solid #D2D2D6 !important;
}

input[type=text]:focus {
  outline: none;
}

.flex-checkbox {
  display: flex;
  align-items: center;
}

.needs {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #1D1C1E;
}

.needs-chat{
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #1D1C1E;
}

.submit-button {
  width: 100%;
  height: 55px;
  background: #007AFF;
  border-radius: 12px;
  border: none;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
}

.submit-button-chat {
  width: 100%;
  height: 45px;
  background: #007AFF;
  border-radius: 12px;
  border: none;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
}

.close-button-chat{
  width: 100%;
  height: 45px;
  background: #ED5E68;
  border-radius: 12px;
  border: none;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
}


.we-are {
  font-family: 'Garnett';
  font-style: bold !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 67px;
  text-align: center;
  color: #000000;
}

.about-txt {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #18181B;
}

.about-us-box {
  width: 100%;
  background: #36309E;
  box-shadow: 2px 4px 36px rgba(205, 205, 205, 0.15);
  border-radius: 20px;
  padding: 4%;
}

.us-box {
  width: 100%;
  background: #36309E;
  height: 350px;
  box-shadow: 2px 4px 36px rgba(205, 205, 205, 0.15);
  border-radius: 20px;
  padding: 4%;
}


.about-us-heading {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #AAA7D8;
}

.about-us-content {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
}

.locate-heading {
  font-family: 'Garnett';
  font-style: bold !important;
  font-weight: 800;
  font-size: 48px;
  line-height: 67px;
  text-align: center;
  color: #FFFFFF;
}

.locate-txt {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #D6D8FB;
}


.copy-txt {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding-top: 38px;
  line-height: 23px;
  text-align: center;
  color: #D6D8FB;
}

.map-box {
  width: 100%;
  height: 600px;
  background: #080B52;
  border: 3px solid #FFFFFF;
  border-radius: 19px;
  padding: 2%;
}

.mapwrapper {
  border-radius: 10px;
  overflow: hidden;
  height: 400px;
  width: 100%;
  height: 100%;
}

.dropdown-item {
  font-family: 'Circular Std' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  color: #000000 !important;
  text-align: left !important;
}

.dropdown-item:hover {
  background-color: white !important;
}

.fa-arrow-right-long {
  padding-left: 2%;
  position: relative;
  top: 2px;
}

.dropdown-menu {
  width: 1000px !important;
  margin-top: 25% !important;
}

.item-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #A9A8B4;
  padding-left: 5%;
}

.main-img {
  position: relative;
  top: -23px;
}

.e-commerce-main {
  position: relative;
  top: 8px;
}

.web-design {
  font-family: 'Garnett';
  font-style: bold;
  font-weight: 800;
  font-size: 64px;
  line-height: 89px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.talk-button {
  width: 100%;
  height: 65px;
  background: #007AFF;
  border-radius: 59px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  border: none;
  color: #FFFFFF;
}


.about-talk-button {
  width: 25%;
  height: 65px;
  display: table;
  margin: auto;
  background: #007AFF;
  text-decoration: none;
  border-radius: 59px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  border: none;
  color: #FFFFFF;
}


.learn-more-button {
  width: 100%;
  height: 65px;
  border: 2px solid #FFFFFF;
  border-radius: 59px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  background: #36309E;
  line-height: 30px;
  color: #FFFFFF;
}

.company-txt {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  padding-right: 10%;
  color: #FFFFFF;
}

.about-contact-txt {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #36309E;
}

.bg-yellow{
  background-color: #FFEF98;
}

.space {
  padding-top: 5%;
  padding-bottom: 8%;
}

.left-padding {
  padding-left: 10% !important;
}

.video-box {
  width: 100%;
  position: relative;
  left: 5px;
  border: 6px solid #8B86E2;
  filter: drop-shadow(0px 3.40773px 16.1867px rgba(0, 0, 0, 0.05));
  border-radius: 40px;
}

.video {
  width: 100%;
  border-radius: 40px;
  overflow: hidden;
}

svg.video-overlay-play-button {
  border-radius: 30px;
}

.video-wrapper {
  position: relative;
}

.video-wrapper>video {
  width: 100%;
  vertical-align: middle;
  cursor: pointer;
}

.video-wrapper>video.has-media-controls-hidden::-webkit-media-controls {
  display: none;
}

.video-overlay-play-button {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px calc(50% - 50px);
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  opacity: 0.95;
  cursor: pointer;
  background-image: linear-gradient(transparent, #000);
  transition: opacity 150ms;
}

.video-overlay-play-button:hover {
  opacity: 1;
}

.video-overlay-play-button.is-hidden {
  display: none;
}

.who-we-are {
  font-family: 'Garnett';
  font-style: bold !important;
  font-weight: 800;
  font-size: 48px;
  line-height: 67px;
  text-align: center;
  color: #000000;
}

.who-zasta {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #18181B;
}

.accordion-body {
  background: #36309E !important;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #C3C1EC;
}

button.accordion-button.collapsed {
  border: 1px solid #CECECE !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  margin-top: 3%;
}

.accordion-collapse.collapse.show {
  border: 1px solid #CECECE !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
}

.accordion-item {
  border: none;
}

.accordion-flush .accordion-item .accordion-button {
  box-shadow: none !important;
  background: #36309E !important;
  border: 2px solid #FFFFFF !important;
  margin-top: 4% !important;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #F4F3FC;
}

.accordion-flush .accordion-item .accordion-button {
  color: white !important;
}

.accordion-button:not(.collapsed) {
  color: #007AFF !important;
  border: 1px solid #CECECE !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
}

.accordion-button {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
  text-align: center;
  color: #1E1F21;
}

/* Custom style */
.accordion-button::after {
  background-image: url('assets/images/plus.svg') !important;
  transform: scale(.7) !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url('assets/images/minus.svg') !important;
  transform: scale(.7) !important;
}

.nav-pills .v-nav-link.active,
.nav-pills .show>.v-nav-link {
  background-color: #36309E !important;
}

.v-nav-link {
  background-color: #080B52 !important;
  border-radius: 12px !important;
  padding: 8% !important;
}

.organisation-v {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #FFFFFF;
}

.zasta-v {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
  padding-left: 4%;
}

.vision-img{
  width: 100%;
  border-radius: 10px;
}

.next-img{
  width: 110px;
}

.ionic-img{
 width: 70px;
}

.php-image{
  width: 30px;
}
.bg-color{
  background-color: rgba(118, 109, 244, 0.22);
}

.join {
  font-family: 'Garnett';
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 67px;
  text-align: center;
  color: #000000;
}

.join-description {
  font-family: 'Circular Std';
  font-style: bold;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #18181B;
}

.vacancy-position {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.04em;
  color: #FFFFFF;
}

.position-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #C7C5EA;
}

.apply-button {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  background: #36309E;
  width: 100%;
  height: 60.02px;
  border: 1px solid #FFFFFF;
  border-radius: 35px;
}

.condition {
  font-family: 'Garnett';
  font-style: bold !important;
  font-weight: 800;
  font-size: 64px;
  line-height: 89px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding-top: 7%;
}

.hide {
  width: 80%;
}

.vacancy-box {
  width: 100%;
  background: #36309E;
  box-shadow: 2px 4px 36px rgba(205, 205, 205, 0.15);
  border-radius: 20px;
  padding: 3%;
}

.about-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #FFFFFF;
}

.e-mail-box {
  width: 90%;
  height: 45px;
  background: #FFFFFF;
  border-radius: 4px;
  padding-left: 5%;
  padding-right: 10%;
}

.box-position {
  position: relative;
}

.policy-para {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #7E7E84;
}

.policy-heading {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: black;
}

.footer-links {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.09em;
  text-decoration: none !important;
  color: #FFFFFF !important;
}

.send-img {
  position: absolute;
  right: 15px;
  top: 28px;
  cursor: pointer;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #36309E !important;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF !important;
}

.nav-tabs {
  border: none !important;
}

.nav-tabs .nav-link {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: #918DD0 !important;
}

.project {
  font-family: 'Garnett';
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 67px;
  text-align: center;
  color: #000000;
}

.zasta-project {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #18181B;
}

.project-box {
  width: 100%;
  background: #EAE9F4;
  border-radius: 10px;
  padding: 3%;
}

.information-box {
  width: 100%;
  background: #080B52;
  border-radius: 10px;
  padding: 5%;
}

.submit-button-project {
  width: 204px;
  height: 55px;
  background: #007AFF;
  border-radius: 10px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  border: none;
  color: #FFFFFF;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
  font-size: 20px;
}


.zasta-data {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  letter-spacing: 0.04em;
  color: #FFFFFF;
}

.zasta-description-data {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #CED0EE;
}

.data-box {
  width: 100%;
  border: 2px solid #FFFFFF;
  border-radius: 8px;
  padding: 5%;
}

.data-box-data {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 20px;
  color: #FFFFFF;
  padding-left: 4%;
}

.icon-space {
  padding-left: 10px;
}

.icon-space-video {
  margin-left: 8%;
}

.icon-space-ads {
  margin-left: 10%;
}

.navigation {
  font-family: 'Circular Std';
  font-style: bold !important;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: #000000 !important;
  text-decoration: none !important;
}

.demo-btn {
  width: 163px;
  height: 50px;
  background: #007AFF;
  border-radius: 8px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
  border: none;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
  color: #007AFF !important;
}

.product-link{
  width: 127px !important;
}

.item-space {
  margin-left: 8%;
}

.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.features-v {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  padding-left: 3%;
  color: white;
}

.explain-features {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  text-align: left;
  padding-left: 15% !important;
  line-height: 23px;
  color: white;
}

.person-box {
  width: 100%;
  padding: 5%;
  background: #36309E;
  border-radius: 8px;
}

.person-name {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
}

.person-role {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #D5D3F5;
  text-align: center;
}

.dropdown-toggle::after {
  display: none !important;
}

.fa-caret-down {
  padding-left: 7%;
  position: relative;
  top: 2px;
}

.marketing-content-box {
  background: #FFFFFF;
  box-shadow: -8.86735px 11.2857px 57px rgba(243, 243, 243, 0.3);
  border-radius: 2.41837px;
  height: 100%;
  width: 100%;
  padding-bottom: 10%;
  z-index: 1;
}

.bg-color {
  background: #FAFAFA;
  padding-bottom: 50px;
}

.marketing-content {
  font-family: Circular Std;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #1E1F21;
}

.icon-content {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #4F4F4F;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-space-instagram {
  margin-left: 8%;
}

.center {
  display: table;
  margin: auto;
}

.pricing-section {
  background: #FFEF98;
}

.pricing-heading {
  font-family: 'Garnett';
  font-style: italic;
  font-weight: 800;
  font-size: 48px;
  line-height: 67px;
  text-align: center;
  color: #000000;
}

.pricing-company {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.pricing-box {
  box-sizing: border-box;
  width: 100%;
  background: #FFFFFF;
  border: 3px solid #36309E;
  box-shadow: 2px 4px 36px rgba(183, 182, 182, 0.15);
  border-radius: 15px;
  padding: 25px;
}

.price-plan {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #18181B;
}

.price {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #36309E;
}

.price-duration {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #8F959B;
}


.price-plan-suggest {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: white;
}

.price-suggest {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: white;
}

.price-duration-suggest {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #8F959B;
}

.rating-suggest {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: white;
}

.plan-description-suggest {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: #D2D1E4;
}

.plans-suggest {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-top: 5px;
  padding-left: 3px;
  color: white;
}



.flexible {
  display: flex;
}

.rating {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #1E1F21;
}

.plan-description {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: #2C2D34;
}

.plans {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-top: 5px;
  padding-left: 3px;
  color: #36309E;
}

.plan-button {
  box-sizing: border-box;
  width: 95%;
  height: 55px;
  border: 2px solid #36309E;
  border-radius: 5px;
  background-color: white;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #36309E;
}


.testing-img {
  width: 25%;
}

.suggest {
  width: 50%;
  margin: auto !important;
  display: table !important;
  height: 40px;
  background: linear-gradient(180deg, #9793E4 0%, #625BD9 100%);
  border-radius: 47px;
  position: absolute;
  top: -17px;
  left: 100px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
}

.suggest-box {
  background: #36309E;
  box-shadow: 2px 130px 85px rgba(163, 163, 163, 0.15);
  border-radius: 15px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 25px;
}

@keyframes scroll{
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(calc(-220px * 7));
  }
}

.slider{
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}


.slider .slider-track{
  animation: scroll 40s linear infinite;
  display: flex;
  justify-content: space-evenly;
  width: calc(220px * 14);
}

.slider .slide{
  height: 100px;
  width: 160px;
}
@media (max-width:1440px) and (min-width:1199.9px) {
  .space-sm {
    padding-bottom: 5% !important;
  }

  .img-space {
    margin-top: 7%;
  }

  .catagory-name {
    font-size: 22px;
  }

  .catagory-name-font {
    font-size: 20px;
  }

  .suggest {
    left: 90px;
    font-size: 17px;
  }

  .icon-content {
    font-size: 13px;
  }

  .marketing-content {
    margin-left: 1%;
  }

  .software {
    font-size: 45px;
    line-height: 70px;
  }

  .privacy {
    font-size: 55px;
  }

  .dropdown-menu {
    width: 900px !important;
  }

  .needs {
    font-size: 17px;
  }
  .dropdown-item {
    font-size: 15px !important;
  }
}

@media (max-width:1200px) and (min-width:991.9px) {
  .web-design {
    font-size: 45px;
    line-height: 65px;
  }

  .mobile-feature-box{
    height: 400px;
  }
.js-img{
  position: relative;
  right: 22px;
}
  .product-link{
    width: 115px !important;
  }

  .navigation{
    font-size: 16px;
  }
  
  .catagory-name {
    font-size: 19px;
  }

  .catagory-name-font {
    font-size: 17px;
  }

  .suggest {
    left: 70px;
    font-size: 16px;
  }

  .icon-content {
    font-size: 10px;
  }

  .feature-heading {
    font-size: 19px;
  }

  .item-description {
    padding-left: 8% !important;
  }

  .dropdown-item {
    font-size: 13px !important;
  }

  .dropdown-menu {
    width: 740px !important;
  }

  .item-description {
    font-size: 12px;
  }

  .features-v {
    font-size: 17px;
    text-align: left;
  }

  .explain-features {
    font-size: 16px;
  }

  .privacy {
    font-size: 50px;
  }

  .explain-features {
    padding-left: 20% !important;
  }

  .software {
    font-size: 35px;
    line-height: 55px;
  }

  .contact-us {
    font-size: 60px !important;
  }

  .contact-box {
    margin-top: -45%;
  }

  .needs {
    font-size: 14px;
  }

  .space-sm {
    padding-bottom: 5%;
  }

  .nav-pills .nav-link {
    width: 350px !important;
  }

  .nav-pills {
    font-size: 18px !important;
  }

  .v-nav-link {
    width: 330px !important;
  }

  .mission {
    margin-left: 0px;
  }

  .value {
    margin-left: 0px;
  }

  .zasta-v {
    font-size: 20px;
  }

}

@media (max-width:992px) and (min-width:767.9px) {
  .contact-box {
    width: 100%;
  }
  .footer-flex{
    display: table;
    margin: auto;
  }
  .logo-footer{
    width: 25%;
  }
  .mobile-feature-box{
    height: 475px;
  }
  .catagory-name {
    font-size: 14px;
  }

  .catagory-name-font {
    font-size: 13px;
  }

  .copy-txt {
    padding-top: 0px !important;
  }
  .img-space {
    margin-top: 7%;
  }

  .feature-heading {
    font-size: 20px;
  }

  .heading {
    padding-top: 0px;
  }

  .suggest {
    left: 160px;
  }

  .suggest-box {
    margin-top: 7%;
  }

  .marketing-content {
    font-size: 13px;
  }

  .second-item {
    margin-top: 10px !important;
  }

  .item-description {
    padding-left: 4%;
  }

  .fa-caret-down {
    padding-left: 1%;
    top: 1px;
  }

  .dropdown-menu {
    width: 700px !important;
  }

  .second-box {
    margin-top: 4%;
  }

  .dropdown-menu {
    margin-top: 1% !important;
  }

  .condition {
    font-size: 45px;
    line-height: 65px;
  }

  .nav-tabs .nav-link {
    font-size: 15px;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    font-size: 13px;
  }

  .space-sm {
    padding-bottom: 5%;
  }

  .arrow-img {
    position: relative;
    bottom: 40%;
    width: 57%;
  }

  .space-sm {
    padding-bottom: 5%;
  }

  .nav-pills {
    margin-left: 125px !important;
  }

  .zasta-about {
    font-size: 50px !important;
    line-height: 60px !important;
  }

  .hide {
    display: none;
  }

  .item-space {
    margin-left: 1%;
    padding-top: 3%;
  }

  .ninja-img {
    width: 50%;
  }

  .responsive-box {
    margin-top: 20px;
  }
  .about-talk-button{
    width: 50%;
  }
  .mobile-box{
    margin-top: 17px;
  }
}

@media (max-width:768px) and (min-width:575.9px) {
  .condition {
    font-size: 40px;
    line-height: 60px;
  }
  .footer-flex{
    display: table;
    margin: auto;
  }
  .logo-footer{
    width: 25%;
  }
  .about-talk-button{
    width: 50%;
  }
  .img-space {
    margin-top: 7% !important;
  }
  .copy-txt {
    padding-top: 0px !important;
  }
  .gape{
    margin-top: 4% !important;
  }

  .tab-points{
    padding-top: 4%;
  }

  .suggest {
    left: 120px;
  }

  .suggest-box {
    margin-top: 7%;
  }

  .icon-space {
    margin-left: 2%;
  }

  .icon-space-ads {
    margin-left: 4%;
  }

  .icon-space-video {
    margin-left: 5%;
  }

  .ninja-img {
    width: 10% !important;
  }

  .second-item {
    margin-top: 10px !important;
  }

  .item-description {
    padding-left: 4%;
  }

  .fa-caret-down {
    padding-left: 1%;
    top: 1px;
  }

  .dropdown-menu {
    width: 500px !important;
  }

  .dropdown-menu {
    margin-top: 1% !important;
  }

  .icon-space-instagram {
    margin-left: 3%;
  }

  .second-box {
    margin-top: 4%;
  }

  .explain-features {
    padding-left: 20% !important;
  }

  .item-space {
    margin-left: 1%;
    padding-top: 3%;
  }

  .nav-tabs .nav-link {
    font-size: 13px;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    font-size: 13px;
  }

  .zasta-about {
    font-size: 40px !important;
    line-height: 50px !important;
  }

  .contact-box {
    width: 100%;
  }

  .arrow-img {
    position: relative;
    bottom: 45%;
    width: 45%;
  }

  .software {
    font-size: 45px;
  }

  .space-sm {
    padding-bottom: 5%;
  }

  .we-are {
    font-size: 42px !important;
  }

  .who-we-are {
    font-size: 35px;
    line-height: 60px;
  }

  .margin {
    margin-top: 8% !important;
  }

  .vacancy-position {
    text-align: center;
  }

  .privacy {
    font-size: 50px !important;
  }

  .background {
    height: 250px;
  }

  .hide {
    display: none;
  }

  .ninja-img {
    width: 20%;
  }
  .mobile-box{
    margin-top: 17px;
  }
  
}

@media (max-width:576px) and (min-width:424.9px) {
  .condition {
    font-size: 30px;
    line-height: 50px;
  }
  .js-img{
    position: relative;
    right: 30px;
  }
  .footer-flex{
    display: table;
    margin: auto;
  }
  .about-talk-button{
    width: 50%;
  }
  .tab-points{
    padding-top: 4%;
  }
  .logo-footer{
    width: 25%;
  }
  .copy-txt {
    padding-top: 0px !important;
  }
  .gape{
    margin-top: 4% !important;
  }

  .img-space {
    margin-top: 7% !important;
  }

  .suggest {
    left: 105px;
    font-size: 16px;
  }

  .suggest-box {
    margin-top: 7%;
  }

  .second-item {
    margin-top: 10px !important;
  }

  .fa-caret-down {
    padding-left: 1%;
    top: 1px;
  }

  .dropdown-menu {
    width: 350px !important;
  }

  .dropdown-menu {
    margin-top: 1% !important;
  }

  .second-box {
    margin-top: 4%;
  }

  .item-space {
    margin-left: 1%;
    padding-top: 3%;
  }

  .data-box-data {
    font-size: 16px;
  }

  .icon-space-instagram {
    margin-left: 3%;
  }

  .icon-space-ads {
    margin-left: 4%;
  }

  .icon-space-video {
    margin-left: 5%;
  }

  .icon-space {
    margin-left: 2%;
  }

  .features-v {
    font-size: 20px;
  }

  .explain-features {
    padding-left: 20% !important;
    font-size: 16px;
  }

  .nav-tabs .nav-link {
    font-size: 13px;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    font-size: 13px;
  }

  .web-design {
    font-size: 45px;
    line-height: 65px;
  }

  .background {
    height: 250px;
  }

  .privacy {
    font-size: 40px !important;
  }

  .zasta-privacy {
    font-size: 16px !important;
  }

  .start-description {
    font-size: 15px !important;
  }

  .zasta-about {
    font-size: 35px !important;
    line-height: 45px !important;
  }

  .software {
    font-size: 45px;
    line-height: 65px;
  }

  .nav-pills {
    margin-left: 50px !important;
  }

  .arrow-img {
    position: relative;
    bottom: 45%;
    width: 45%;
  }

  .contact-box {
    margin-top: -43%;
  }

  .we-are {
    font-size: 35px !important;
  }

  .contact-us {
    font-size: 55px;
  }

  .space-sm {
    padding-bottom: 5%;
  }

  .mission {
    margin-left: 0px;
  }

  .value {
    margin-left: 0px;
  }

  .req-field {
    font-size: 14px;
  }

  .who-we-are {
    font-size: 30px;
    line-height: 55px;
  }

  .nav-pills .nav-link {
    width: 350px !important;
  }

  .nav-pills {
    font-size: 15px !important;
  }

  .vacancy-position {
    text-align: center;
  }

  .question {
    font-size: 36px;
    line-height: 55px;
  }
.process{
  font-size: 35px;
}
  .margin {
    margin-top: 8% !important;
  }
  

  .join {
    font-size: 40px;
    line-height: 55px;
  }

  .started {
    font-size: 35px;
    line-height: 60px;
  }

  .hide {
    display: none;
  }

  .needs {
    font-size: 16px;
  }

  .project {
    font-size: 40px;
    line-height: 60px;
  }

  .ninja-img {
    width: 20%;
  }

  .pricing-heading {
    font-size: 36px;
    line-height: 55px;
  }

  .portfolio {
    font-size: 36px;
    line-height: 55px;
  }
  .ionic-img {
    width: 40px;
}
.next-img {
  width: 65px;
}
.mobile-box{
  margin-top: 17px;
}
}

@media (max-width:425px) and (min-width:319.9px) {
  .web-design {
    font-size: 35px;
    line-height: 55px;
  }
  .mobile-box{
    margin-top: 17px;
  }
  .js-img{
    position: relative;
    right: 55px;
  }
  .ionic-img {
    width: 35px;
}
.next-img {
  width: 60px;
}
  .footer-flex{
    display: table;
    margin: auto;
  }
  .about-contact-img{
    width: 50%;
  }
  .copy-txt {
    padding-top: 0px !important;
    font-size: 14px;
  }
  .about-talk-button{
    width: 50%;
  }
  .dropdown-item{
    font-size: 16px !important;
  }
  .logo-footer{
    width: 25%;
  }
  .tab-points{
    padding-top: 6%;
  }

  .gape{
    margin-top: 4% !important;
  }

  .img-space {
    margin-top: 7% !important;
  }

  .suggest {
    left: 80px;
    font-size: 16px;
  }

  .suggest-box {
    margin-top: 7%;
  }

  .portfolio {
    font-size: 36px;
    line-height: 55px;
  }

  .pricing-heading {
    font-size: 36px;
    line-height: 55px;
  }

  .icon-space-instagram {
    margin-left: 3%;
  }

  .ninja-img {
    width: 20%;
  }

  .second-item {
    margin-top: 10px !important;
  }

  .fa-caret-down {
    padding-left: 1%;
    top: 1px;
  }

  .dropdown-menu {
    width: 300px !important;
  }

  .dropdown-menu {
    margin-top: 1% !important;
  }

  .second-box {
    margin-top: 4%;
  }

  .item-space {
    margin-left: 1%;
    padding-top: 3%;
  }

  .project {
    font-size: 35px;
    line-height: 55px;
  }

  .zasta-data {
    font-size: 23px;
  }

  .data-box-data {
    font-size: 14px;
  }

  .condition {
    font-size: 25px;
    line-height: 35px;
  }

  .features-v {
    font-size: 16px;
  }

  .explain-features {
    padding-left: 25% !important;
    font-size: 15px;
  }

  .nav-tabs .nav-link {
    font-size: 13px;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    font-size: 13px;
  }

  .zasta-about {
    font-size: 30px !important;
    line-height: 40px !important;
  }

  .privacy {
    font-size: 30px !important;
    line-height: 60px !important;
  }

  .question {
    font-size: 30px;
    line-height: 55px;
  }
.process{
  font-size: 35px;
}
  .background {
    height: 220px;
  }

  .zasta-privacy {
    font-size: 15px !important;
  }

  .started {
    font-size: 30px;
    line-height: 55px;
  }

  .nav-pills {
    font-size: 14px !important;
    margin-left: 20px;
  }

  .start-description {
    font-size: 15px !important;
  }

  .mission {
    margin-left: 0px;
  }

  .value {
    margin-left: 0px;
  }

  .who-we-are {
    font-size: 25px;
    line-height: 40px;
  }

  .about-us-content {
    font-size: 12px;
  }

  .learn-more-button {
    height: 45px;
    font-size: 18px;
  }

  .about-img {
    width: 100% !important;
  }

  .vacancy-position {
    text-align: center;
  }

  .join {
    font-size: 40px;
    line-height: 55px;
  }

  .locate-heading {
    font-size: 35px;
  }

  .software {
    font-size: 35px;
    line-height: 60px;
  }

  .talk-button {
    height: 45px;
    font-size: 18px;
  }

  .we-are {
    font-size: 25px;
    line-height: 40px;
  }

  .arrow-img {
    position: relative;
    bottom: 45%;
    width: 45%;
  }

  .req-field {
    font-size: 14px;
  }

  .needs {
    font-size: 15px;
  }

  .contact-box {
    margin-top: -47%;
  }

  .contact-us {
    font-size: 40px;
  }

  .space-sm {
    padding-bottom: 5%;
  }

  .nav-pills .nav-link {
    width: 255px !important;
  }

  .margin {
    margin-top: 8% !important;
  }

  .hide {
    display: none;
  }
}





/*testimonial*/

#testimonial-area .section-heading h2 {
  font-size: 48px;
  line-height: 58px;
}

.client-name {
  font-family: 'Circular Std' !important;
  font-style: bold !important;
  font-weight: 900 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  text-align: center !important;
  color: #000000 !important;
}

.testi-wrap {
  position: relative;
  height: 600px;
  margin-top: -80px;
}

.client-single {
  margin-top: 20px;
  text-align: center;
  position: absolute;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.zasta-about {
  font-family: 'Garnett';
  font-style: bold !important;
  font-weight: 800;
  font-size: 64px;
  line-height: 89px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.client-info,
.client-comment {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.client-single.inactive .client-comment,
.client-single.inactive .client-info {
  display: none;
}

.client-single.inactive .client-comment,
.client-single.inactive .client-info {
  opacity: 0;
  visibility: hidden;
}

.client-single.position-1 {
  -webkit-transform: scale(0.65);
  transform: scale(0.65);
}

.client-single.position-2 {
  left: -40px;
  top: 105px;
}

.client-single.position-3 {
  left: -60px;
  top: 240px;
  -webkit-transform: scale(0.4) !important;
  transform: scale(0.4) !important;
}

.client-single.position-4 {
  left: 55px;
  top: 380px;
}

.client-single.position-5 {
  top: 30px;
  right: 55px;
}

.client-single.position-6 {
  top: 225px;
  right: -40px;
}

.client-single.position-7 {
  top: 400px;
  right: 45px;
  -webkit-transform: scale(0.4) !important;
  transform: scale(0.4) !important;
}

.client-single.active {
  top: 10%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 10;
  width: 70%;
}

.client-single.active .client-comment,
.client-single.active .client-info {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.client-single:not(.active) {
  -webkit-transform: scale(0.55);
  transform: scale(0.55);
  z-index: 99;
}

.client-single.active .client-img {
  width: 160px;
  height: 160px;
  margin: 0 auto 24px;
  position: relative;
}

.client-single.active .client-img:before {
  border-radius: 100%;
  content: "";
  padding: 5px;
  width: 160px;
  height: 160px;
  top: -4px;
  left: 0px;
  position: absolute;
  z-index: -1;
}

.client-single .client-img img {
  width: 150px;
  border-radius: 50%;
  border: 8px solid white !important;
  cursor: pointer;
}

.client-single.active .client-img img {
  max-width: 160px;
  margin: 0 auto 24px;
  border: 0;
}

.client-comment {
  padding: 0 30px;
}

.client-comment h3 {
  font-family: 'Garnett';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
  color: #000000;
}

.client-info h3 {
  color: #000;
  font-weight: 600;
  margin-bottom: 4px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #testimonial-area .section-heading h2 {
    font-size: 30px;
  }

  .client-comment h3 {
    font-size: 18px;
    line-height: 28px;
  }

  .client-name {
    font-size: 18px !important;
  }

  .client-single.active {
    width: 60%;
  }

  .client-single:not(.active) {
    -webkit-transform: scale(0.55);
    transform: scale(0.35);
  }

  .client-single.position-3,
  .client-single.position-7 {
    -webkit-transform: scale(0.3) !important;
    transform: scale(0.3) !important;
  }

  .client-single.active .client-img img {
    max-width: 100px;
  }

  .client-single.active .client-img::before {
    padding: 5px;
    width: 108px;
    height: 108px;
    top: -4px;
    left: 6px;
  }

  .client-single.active .client-img {
    width: 120px;
    height: 100px;
  }

  .testi-wrap {
    height: 500px;
  }

  #testimonial-area {
    padding: 100px 0 0;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  #testimonial-area .section-heading h2 {
    font-size: 30px;
  }

  .client-comment h3 {
    font-size: 14px;
    line-height: 26px;
  }

  .client-name {
    font-size: 14px !important;
  }

  .client-single.active {
    width: 60%;
  }

  .client-comment span i {
    font-size: 40px;
  }

  .client-single:not(.active) {
    -webkit-transform: scale(0.55);
    transform: scale(0.35);
  }

  .client-single.position-5,
  .client-single.position-7 {
    right: 0;
  }

  .client-single.position-4 {
    left: 0;
  }

  .client-single.position-3,
  .client-single.position-7 {
    -webkit-transform: scale(0.3) !important;
    transform: scale(0.3) !important;
  }

  .client-single.active .client-img img {
    max-width: 80px;
  }

  .client-single.active .client-img::before {
    padding: 5px;
    width: 88px;
    height: 88px;
    top: -4px;
    left: 16px;
  }

  .client-single.active .client-img {
    width: 120px;
    height: 100px;
  }

  .testi-wrap {
    height: 500px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  #testimonial-area .section-heading h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .client-comment h3 {
    font-size: 14px;
    line-height: 26px;
  }

  .client-name {
    font-size: 14px !important;
  }

  .client-single.active {
    width: 80%;
  }

  .client-comment span i {
    font-size: 40px;
  }

  .client-single:not(.active) {
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
  }

  .client-single.position-5,
  .client-single.position-7,
  .client-single.position-6 {
    right: -70px;
  }

  .client-single.position-4 {
    left: -60px;
  }

  .client-single.position-3 {
    left: -75px;
  }

  .client-single.position-3,
  .client-single.position-7 {
    -webkit-transform: scale(0.25) !important;
    transform: scale(0.25) !important;
  }

  .client-single.active .client-img img {
    max-width: 80px;
  }

  .client-single.active .client-img::before {
    padding: 5px;
    width: 88px;
    height: 88px;
    top: -4px;
    left: 16px;
  }

  .client-single.active .client-img {
    width: 120px;
    height: 100px;
  }

  .testi-wrap {
    height: 600px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 359px) {
  #testimonial-area .section-heading h2 {
    font-size: 30px;
  }

  .client-comment h3 {
    font-size: 14px;
    line-height: 26px;
  }

  .client-name {
    font-size: 14px !important;
  }

  .client-single.active {
    width: 80%;
  }

  .client-comment span i {
    font-size: 40px;
  }

  .client-single:not(.active) {
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
  }

  .client-single.position-5,
  .client-single.position-7,
  .client-single.position-6 {
    right: -70px;
  }

  .client-single.position-4 {
    left: -60px;
  }

  .client-single.position-3 {
    left: -75px;
  }

  .client-single.position-3,
  .client-single.position-7 {
    -webkit-transform: scale(0.25) !important;
    transform: scale(0.25) !important;
  }

  .client-single.active .client-img img {
    max-width: 80px;
  }

  .client-single.active .client-img::before {
    padding: 5px;
    width: 88px;
    height: 88px;
    top: -4px;
    left: 16px;
  }

  .client-single.active .client-img {
    width: 120px;
    height: 100px;
  }

  .testi-wrap {
    height: 500px;
  }
}






.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#007AFF;
  cursor: pointer;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
  z-index:100;
  animation-name: pulse;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}
@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(17, 58, 255, 0.64);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(17, 154, 255, 0.7);
	}
}
.my-float{
	margin-top:16px;
}

.bot{
  width: 50%;
}


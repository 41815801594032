.nav-pills{
    display: flex !important;
    margin-left: 13% ;
}

.banner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 20px;
  }
  
  .text-content {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .started,
  .start-description {
    font-size: 1.5rem;
    margin: 0;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  
  /* Add colorful animations to H1 and H2 */
  .started {
    animation: gradientText 3s infinite linear; /* Adjust animation properties as needed */
  }
  
  .start-description {
    animation: gradientText 4s infinite linear alternate-reverse; /* Adjust animation properties as needed */
  }
  
  @keyframes gradientText {
    0% {
      background-image: linear-gradient(45deg, #ff00a9, #00ffcc); /* Gradient colors for start position */
    }
    25% {
      background-image: linear-gradient(45deg, #ffcc00, #00a9ff); /* Gradient colors for 25% position */
    }
    50% {
      background-image: linear-gradient(45deg, #00ffcc, #ff00a9); /* Gradient colors for 50% position */
    }
    75% {
      background-image: linear-gradient(45deg, #00a9ff, #ffcc00); /* Gradient colors for 75% position */
    }
    100% {
      background-image: linear-gradient(45deg, #ff00a9, #00ffcc); /* Gradient colors for end position */
    }
  }
  
  /* Ensure elements stay in the same line for larger screens */
  @media (min-width: 768px) {
    .banner-container {
      flex-direction: row;
    }
  
    .text-content {
      text-align: left;
      margin-right: 20px;
    }
  
    .video-background {
      width: 50%;
    }
  }
  
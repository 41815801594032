.staffing-solutions {
    text-align: center;
    margin: 20px;
  }
  
  .company-name {
    color: #0066FF;
  }
  
  .sub-heading {
    margin-top: 10px;
    font-size: 20px;
  }
  
  .content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
  }
  
  .section {
    display: flex;
    align-items: center;
    margin: 20px;
  }
  
  .section-image {
    width: 300px;
    height: 200px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 5px;
  }
  
  .section-content {
    text-align: left;
  }
  
  .section-content h3 {
    margin-bottom: 10px;
  }
  
  .cta-section {
    margin-top: 50px;
    padding: 30px;
    background-color: #F2F2F2;
    text-align: center;
  }
  
  .cta-section h2 {
    color: #0066FF;
  }
  
  .cta-section p {
    margin-top: 10px;
  }
  
  .contact-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #0066FF;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .contact-button:hover {
    background-color: #0052CC;
  }
  